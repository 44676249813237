<template>
  <div
    class="topper-banner"
    :style="{
      backgroundColor: type === 'carousel' ? data[bannerIndex].bgColor : '#141c2f'
    }"
  >
    <div
      v-for="(item, index) in data"
      v-show="bannerIndex == index"
      :key="index"
      class="position-bg"
      :style="{
        backgroundColor: `${item.bgColor}`
      }"
    ></div>
    <template v-if="type === 'banner' && data.length > 0">
      <el-carousel arrow="never" height="330px" :attr="data.length" @change="changeCarousel">
        <el-carousel-item v-for="(item, index) in data" :key="index">
          <div
            class="banner-bg"
            :style="{
              backgroundColor: item.bgColor
            }"
          >
            <img :src="item.imgUrl" alt="" />
            <!-- <img v-if="item.type === 1" src="~/assets/images/header-bgs/index-header-banner1.jpg" alt="" />
            <img v-if="item.type === 2" src="~/assets/images/header-bgs/index-header-banner2.jpg" alt="" />
            <img v-if="item.type === 3" src="~/assets/images/header-bgs/index-header-banner1.webp" alt="" />
            <img v-if="item.type === 4" src="~/assets/images/header-bgs/index-header-banner4.webp" alt="" />
            <img v-if="item.type === 5" src="~/assets/images/header-bgs/index-header-banner2.webp" alt="" /> -->
          </div>
        </el-carousel-item>
      </el-carousel>
    </template>

    <template v-else>
      <div class="position-bg" style="background-color: #000233"></div>
      <div class="banner-bg">
        <img src="~/assets/images/header-bgs/index-header-bg2.jpg" alt="" />
      </div>
    </template>
  </div>
</template>

<script setup>
import bannerConfig from '~/constants/banner-config'
import banner from '~/components/layout/banner.vue'
const route = useRoute()
const router = useRouter()
watch(
  () => router.currentRoute.value.name,
  toPath => {
    console.log('toPath', toPath)
    routeNameFirstPart.value = toPath ? toPath.split('_')[0] : ''
    if (bannerConfig[routeNameFirstPart.value]) {
      data.value = bannerConfig[routeNameFirstPart.value]
    }
  }
)
const routeNameFirstPart = ref(route.name ? route.name.split('_')[0] : '')
console.log('routeNameFirstPart', routeNameFirstPart.value)
const data = ref([])

if (bannerConfig[routeNameFirstPart.value]) {
  data.value = bannerConfig[routeNameFirstPart.value]
}
const props = defineProps({
  type: {
    type: String,
    default: 'banner'
  }
})
const bannerIndex = ref(0)

const changeCarousel = e => {
  bannerIndex.value = e
}
</script>

<style lang="scss" scoped>
.topper-banner {
  width: 1400px;
  // background-color: #151b2f;
  // min-width: 1920px;
  z-index: 10;
  margin: 0 auto;
  .position-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 330px;
    z-index: 1;
  }
  :deep(.el-carousel) {
    min-width: 1920px;
    position: relative;
    left: -260px;
    z-index: 2;
    .el-carousel__indicators {
      bottom: 40px;
    }
    .el-carousel__indicator--horizontal {
      padding: 0 5px;

      &.is-active {
        .el-carousel__button {
          background: $primary-color;
        }
      }
    }
    .el-carousel__button {
      width: 40px;
      height: 10px;
      background: #f7f7f8;
      border-radius: 5px;
      opacity: 1;
    }
  }

  .banner-bg {
    height: 330px;

    // background-color: #151b2f;

    img {
      display: block;
      height: 100%;
      margin: 0 auto;
    }
  }
}
</style>
